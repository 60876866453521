<template>
  <t-page>
    <template #header>
      <h3>{{ $t('fields.tenants') }}</h3>
    </template>
    <t-grid>
      <t-grid-cell>
        <index-table :query="query" :fields="fields" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import Tenant from '~/models/Tenant';
import IndexTable from '~/pages/admin/tenants/partials/IndexTable';

export default Vue.extend({
  components: { IndexTable },

  middleware: 'admin',

  data: () => ({
    query: () => new Tenant(),

    fields: ['name', 'slug', 'organizations_count', 'applicants_count', 'actions'],
  }),
});
</script>
